export function isBrowser(): boolean {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )
}

export const scrollToTop = () => {
  window.scrollTo(0, 0)
}
