import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { useGetProductsQuery } from '../../queries'
import { Product } from '../../services/products/types'
import { usePlatoContext } from '../Plato/PlatoProvider'

export type ProductsContext = {
  allProducts: Product[]
  category: string
  products: Product[]
  isLoading: boolean
  setCategory: Dispatch<SetStateAction<string>>
}

export type ProductsProviderProps = PropsWithChildren<{}>

const productsContext = createContext<ProductsContext | undefined>(undefined)

export const ProductsProvider: FC<ProductsProviderProps> = ({ children }) => {
  const { brand } = usePlatoContext()
  const [category, setCategory] = useState<string>('')
  const { isLoading, products } = useGetProductsQuery('', brand)

  const filteredProducts = () => {
    if (category === '') {
      return products || []
    }
    return products?.filter(product => product.category.slug === category) || []
  }
  return (
    <productsContext.Provider
      value={{
        category,
        isLoading,
        products: filteredProducts(),
        allProducts: products || [],
        setCategory,
      }}
    >
      {children}
    </productsContext.Provider>
  )
}

export const useProductsContext = (): ProductsContext => {
  const context = useContext(productsContext)

  if (!context) {
    throw new Error('useProductsContext must be used within ProductsProvider')
  }

  return context
}
