import { isNotClientSide } from './assertions'

export const getStorage = <T>(
  key: string,
  storageType: 'localStorage' | 'sessionStorage',
): T | undefined => {
  if (isNotClientSide()) {
    return
  }
  const item = window[storageType].getItem(key)

  if (item) {
    return JSON.parse(item)
  }
}

export const setStorage = <T>(
  key: string,
  value: T,
  storageType: 'localStorage' | 'sessionStorage',
) => {
  if (isNotClientSide()) {
    return
  }

  window[storageType].setItem(key, JSON.stringify(value))
}

export const removeStorage = (
  key: string,
  storageType: 'localStorage' | 'sessionStorage',
) => {
  if (isNotClientSide()) {
    return
  }

  window[storageType].removeItem(key)
}

export const setLocalStorage = <T>(key: string, value: T) => {
  setStorage(key, value, 'localStorage')
}

export const getLocalStorage = <T>(key: string): T | undefined => {
  return getStorage(key, 'localStorage')
}

export const removeLocalStorage = (key: string) => {
  removeStorage(key, 'localStorage')
}

export const setSessionStorage = <T>(key: string, value: T) => {
  setStorage(key, value, 'sessionStorage')
}

export const getSessionStorage = <T>(key: string): T | undefined => {
  return getStorage(key, 'sessionStorage')
}

export const removeSessionStorage = (key: string) => {
  removeStorage(key, 'sessionStorage')
}
