import { useQuery } from '@tanstack/react-query'

import { getConsultation } from '../../services'

export const useGetConsultationQuery = () => {
  const query = useQuery({
    queryKey: ['consultation'],
    queryFn: () => getConsultation(),
    retry: false,
  })

  return {
    ...query,
    consultation: query.data,
  }
}
