import { useMutation, useQueryClient } from '@tanstack/react-query'

import { usePrescriptionsContext } from '../../modules/Prescriptions/PrescriptionsProvider'
import { createMedicalPrescriptions } from './createMedicalPrescriptions'

export const useCreateMedicalPrescriptionsMutation = () => {
  const queryClient = useQueryClient()
  const { clearCurrentPrescriptions } = usePrescriptionsContext()

  const { error, mutate, ...mutation } = useMutation(
    createMedicalPrescriptions,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['prescriptions'] })
        clearCurrentPrescriptions()
      },
    },
  )

  return {
    error,
    mutate,
    ...mutation,
  }
}
