import 'react-toastify/dist/ReactToastify.css'

import { FC } from 'react'
import { ToastContainer, ToastContainerProps } from 'react-toastify'

export type ToasterProps = Pick<ToastContainerProps, 'autoClose'>

export const Toaster: FC<ToasterProps> = ({ autoClose }) => {
  return (
    <>
      <ToastContainer
        closeOnClick
        draggable
        newestOnTop
        pauseOnFocusLoss
        pauseOnHover
        autoClose={autoClose}
        hideProgressBar={false}
        icon={false}
        position="top-center"
        rtl={false}
        theme="colored"
      />
      <style jsx global={true}>{`
        :root {
          --toastify-color-error: rgba(189, 87, 75, 0.9);
          --toastify-color-success: rgba(117, 219, 157, 0.9)";
          --toastify-toast-min-height: auto;
          --toastify-toast-width: 450px;
        }

        .Toastify__toast-body {
          padding: 0;
          margin: 0;
          opacity: 1;
        }

        .Toastify__toast {
          padding: 0.75rem 1.5rem;
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .Toastify__close-button {
          align-self: center;
        }

        .Toastify__toast-container--top-center {
          top: 2rem;
        }

        .Toastify__progress-bar {
          height: 0.25rem;
        }
      `}</style>
    </>
  )
}
