import { createAxiosInstance, getToken } from '..'
import { PrescriptionProduct } from './getMedicalPrescriptions'

export type createMedicalPrescriptionsParams = {
  products: PrescriptionProduct[]
}
export const createMedicalPrescriptions = async (
  context: createMedicalPrescriptionsParams,
) => {
  const accessToken = getToken({ fromQueryParams: false })

  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const { data } = await axiosInstance.post(
    `/plato/users/me/prescriptions`,
    context,
  )

  return data
}
