import { FC } from 'react'

import { Card, Grid, H3, List, Text } from '../../ui'
import { usePlatoContext } from './PlatoProvider'

type OrderItemProps = {
  name: string
  description: string
  quantity: string
  isBold?: boolean
}
const OrderItem: FC<OrderItemProps> = ({
  name,
  description,
  quantity,
  isBold = false,
}) => {
  return (
    <Grid cols="grid-cols-6">
      <Text className="text-center" isBold={isBold}>
        {quantity}
      </Text>
      <Text isBold={isBold}>{name}</Text>
      <Text
        className="col-span-4"
        dangerouslySetInnerHTML={{ __html: description }}
        isBold={isBold}
      />
    </Grid>
  )
}

export const OrderDetails: FC = () => {
  const { order } = usePlatoContext()
  return (
    <Card>
      <H3 className="mb-4">
        {order.isReconsultation ? 'Reconsultation' : 'Order'} Details
      </H3>
      <Card isShadow className="bg-white">
        <List>
          <OrderItem
            isBold
            description="Description"
            name="Product"
            quantity="Quantity"
          />
          {order.orderItems?.map((item, index) => {
            const description = `${item.product?.plan?.sku || ''} ${
              item.product?.description || ''
            }`
            return (
              <OrderItem
                key={index}
                description={description}
                name={item.product.name}
                quantity={item.quantity.toString()}
              />
            )
          })}
        </List>
      </Card>
    </Card>
  )
}
