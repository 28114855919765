import { createContext, FC, PropsWithChildren, useContext } from 'react'

import {
  useAuthorizationQuery,
  useGetConsultationQuery,
  useGetTreatmentQuery,
} from '../../queries'
import { Treatment } from '../../services'
import { ConsultationDetails } from '../../services/medical/types'

export type PlatoContext = ConsultationDetails & { treatment?: Treatment }

export type PlatoProviderProps = PropsWithChildren<{}>

const platoContext = createContext<PlatoContext | undefined>(undefined)

export const PlatoProvider: FC<PlatoProviderProps> = ({ children }) => {
  const { metadata } = useAuthorizationQuery()
  const { consultation, isLoading } = useGetConsultationQuery()
  const { treatment } = useGetTreatmentQuery(consultation?.visit?.id as string)

  if (!metadata || isLoading) {
    return <></>
  }

  if (!consultation) {
    return <>Unauthorized Access.</>
  }

  const { brand, visit, patient, order, status, startedAt, endedAt } =
    consultation

  return (
    <platoContext.Provider
      value={{
        brand,
        visit,
        patient: { ...patient, name: metadata.name, nric: metadata.id },
        order,
        status,
        treatment,
        startedAt,
        endedAt,
      }}
    >
      {children}
    </platoContext.Provider>
  )
}

export const usePlatoContext = (): PlatoContext => {
  const context = useContext(platoContext)
  if (!context) {
    throw new Error('usePlatoContext must be used within PlatoProvider')
  }

  return context
}
