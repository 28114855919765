import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

export type BoxProps = HTMLAttributes<HTMLDivElement> & {
  padding?: 'p-0' | 'p-2' | 'p-4' | 'p-6' | 'p-8'
  as?: 'header' | 'section' | 'footer' | 'div'
}

// Nothing special just a box
export const Box: FC<BoxProps> = ({
  padding = 'primary',
  className,
  children,
  as = 'div',
  ...props
}) => {
  const Component = as
  return (
    <Component
      className={classNames(padding, className)}
      data-testid="box"
      {...props}
    >
      {children}
    </Component>
  )
}

Box.displayName = 'Box'
