import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createMedicalCertificate } from '../../services'

export const useCreateMc = () => {
  const queryClient = useQueryClient()

  const { error, mutate, ...mutation } = useMutation(createMedicalCertificate, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['medicalCertificates'] })
    },
    retry: false,
  })

  return {
    error,
    mutate,
    ...mutation,
  }
}
