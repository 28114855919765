export const isFunction = <T extends Function = Function>(
  value: any,
): value is T => typeof value === 'function'

export const isNotClientSide = () => typeof window === 'undefined'

export const isValidDate = (date: Date) => {
  return (
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date.valueOf())
  )
}

export const isEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )
}

export const isBothLowerAndUppercase = (password: string) => {
  return /(?=.*[a-z])(?=.*[A-Z])/.test(password)
}

export const isOneLowercase = (password: string) => {
  return /(?=.*[a-z])/.test(password)
}

export const isOneNumberOrSymbol = (password: string) => {
  return /(?=.*\d)|(?=.*\W)/.test(password)
}

export const isAtLeast8Chars = (password: string) => {
  return password.length >= 8
}

export const isValidPassword = (password?: string) => {
  if (!password) {
    return false
  }

  return (
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(password) &&
    isAtLeast8Chars(password)
  )
}
