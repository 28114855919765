import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { forwardRef, InputHTMLAttributes, ReactNode, useId } from 'react'

import { Flex } from '../../layout'
import { FormField } from '../formField/FormField'
import { Label } from '../label/Label'

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  message?: ReactNode
  color?: 'danger'
}
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ message, className, color, children, ...props }, ref) => {
    const id = useId()

    return (
      <FormField color={color} message={message}>
        <Label
          isMarginless
          className="cursor-pointer"
          htmlFor={id}
          isBold={false}
        >
          <Flex alignItems="items-center" gap="gap-2">
            <Flex
              className="relative"
              gap="gap-2"
              justifyContent="justify-center"
            >
              <input
                data-testid="checkbox"
                id={id}
                ref={ref}
                type="checkbox"
                className={classNames(
                  'peer border-gray-400 h-5 w-5 cursor-pointer appearance-none border bg-white shadow-sm transition-all',
                  'disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-400',
                  'checked:bg-black checked:border-black checked:disabled:opacity-40',
                  'focus:border-teal2 focus:outline-none',
                  color === 'danger' && 'border-red-600',
                  className,
                )}
                {...props}
              />

              <FontAwesomeIcon
                className="pointer-events-none absolute text-white"
                icon={faCheck}
              />
            </Flex>
            <span>{children}</span>
          </Flex>
        </Label>
      </FormField>
    )
  },
)

Checkbox.displayName = 'Checkbox'
