import { Brand } from '../medical/types'
import { createAxiosInstance } from '../utils'
import { Product } from './types'

type ProductsResponse = {
  products: Product[]
}

export const getProducts = async (category: string, brand: Brand) => {
  const axiosInstance = await createAxiosInstance()

  const { data } = await axiosInstance.get<ProductsResponse>(
    `/products?providerName=voyager&category=${category}&version=1`,
    { headers: { 'x-brand': brand } },
  )

  return data.products
    .filter(product => product.plans.length > 0)
    .filter(product => product.slug !== 'consultation')
}
