import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { forwardRef, InputHTMLAttributes, useId } from 'react'

import { Flex, List } from '../../layout'
import { Label } from '../label/Label'

export type RadioIconProps = InputHTMLAttributes<HTMLInputElement> & {
  color?: 'danger'
}

export const RadioIcon = forwardRef<HTMLInputElement, RadioIconProps>(
  ({ className, color, children, ...props }, ref) => {
    const id = useId()

    return (
      <Label
        isMarginless
        className={classNames('cursor-pointer inline-block')}
        data-testid="radio-icon"
        htmlFor={id}
        isBold={false}
      >
        <List gap="gap-2">
          <input
            className="peer hidden appearance-none"
            id={id}
            ref={ref}
            type="radio"
            {...props}
          />
          <Flex
            gap="gap-2"
            justifyContent="justify-center"
            className={classNames(
              'relative border rounded-full h-12 w-12 transition-all justify-self-center',
              'peer-checked:bg-teal2 peer-checked:border-teal2',
              color === 'danger'
                ? 'border-red3 bg-red2Light'
                : 'border-transparent bg-neutral3',
              className,
            )}
          >
            <FontAwesomeIcon className="text-white text-lg" icon={faCheck} />
          </Flex>

          <span>{children}</span>
        </List>
      </Label>
    )
  },
)

RadioIcon.displayName = 'RadioIcon'
