import 'tailwindcss/tailwind.css'

import { differenceInDays, format, parseISO } from 'date-fns'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'

import { useGetMc } from '../../queries/mc'
import { useCreateMc } from '../../queries/mc/useCreateMc'
import { Button, Card, Flex, H3, List, Text } from '../../ui'

const McGenerator = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [title, setTitle] = useState('Medical Certificate')
  const [body, setBody] = useState('')
  const [unfitFor, setUnfitFor] = useState('Work')
  const modalRef = useRef<HTMLDivElement>(null)
  const { certificates, refetch } = useGetMc()
  const { mutate, isLoading } = useCreateMc()
  const [errorMessage, setErrorMessage] = useState('')

  const resetData = () => {
    setFromDate('')
    setToDate('')
    setTitle('Medical Certificate')
    setUnfitFor('Work')
    setErrorMessage('')
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    resetData()
  }, [])

  const handleClickOutside = useCallback(
    (event: KeyboardEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        (event.key === 'Escape' || event.key === 'Esc')
      ) {
        closeModal()
      }
    },
    [closeModal],
  )

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('keydown', handleClickOutside)
    } else {
      document.removeEventListener('keydown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('keydown', handleClickOutside)
    }
  }, [isModalOpen, handleClickOutside])

  const calculateDays = (start: string, end: string): number => {
    const startDate = parseISO(start)
    const endDate = parseISO(end)
    return differenceInDays(endDate, startDate)
  }

  useEffect(() => {
    // Update body content based on other fields
    let bodyText = `This is to certify that the patient is Unfit for ${unfitFor}`
    if (fromDate && toDate) {
      const formattedFromDate = format(parseISO(fromDate), 'dd MMMM yyyy')
      const formattedToDate = format(parseISO(toDate), 'dd MMMM yyyy')
      const startDate = Date.parse(fromDate)
      const endDate = Date.parse(toDate)
      const differenceInDays = (endDate - startDate) / (1000 * 60 * 60 * 24)
      bodyText += ` from ${formattedFromDate} to ${formattedToDate} for ${calculateDays(
        fromDate,
        toDate,
      )} ${differenceInDays > 1 ? 'days' : 'day'}.`
    }
    setBody(bodyText)
  }, [fromDate, toDate, unfitFor])

  const sendMC = (event: FormEvent) => {
    event.preventDefault()

    mutate(
      {
        body,
        title,
        category: unfitFor.toLowerCase(),
        startDate: fromDate,
        endDate: toDate,
      },
      {
        onSuccess() {
          refetch()
          // Close the modal after processing
          closeModal()
        },
        onError(error) {
          if (error instanceof Error) {
            const message = error.message
            setErrorMessage(message)
          }
        },
      },
    )
  }
  return (
    <article className="flex flex-col justify-start items-start">
      {isLoading && (
        <div className="overlay flex flex-col">
          <div className="spinner" />
          <Text isBold className="text-black" size="large">
            Generating certificate...
          </Text>
        </div>
      )}

      <Card className="w-full">
        <Flex>
          <H3>Existing Medical Certificates</H3>
          <Button
            className="bg-neutral6 text-base text-black"
            disabled={isLoading}
            size="small"
            type="button"
            onClick={openModal}
          >
            Create New Medical Certificate
          </Button>
        </Flex>
        {certificates.length > 0 &&
          certificates
            .slice()
            .sort((a, b) => b.timestampCreated - a.timestampCreated)
            .map((cert, idx) => (
              <Card key={idx} isShadow className="bg-white w-full my-4">
                <List>
                  <Flex>
                    <Text>Certificate issued to :</Text>
                    <Text isBold>{cert.user.name}</Text>
                  </Flex>
                  <Flex>
                    <Text>Certificate issued on :</Text>
                    <Text isBold>
                      {`${new Date(
                        cert.timestampCreated,
                      ).toLocaleDateString()} ${new Date(
                        cert.timestampCreated,
                      ).toLocaleTimeString()}`}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text>Certificate title :</Text>
                    <Text isBold>{cert.title}</Text>
                  </Flex>

                  <Flex>
                    <Text>Certificate issue from :</Text>
                    <Text isBold>
                      {new Date(cert.startDate).toLocaleDateString()}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text>Certificate issue until :</Text>
                    <Text isBold>
                      {new Date(cert.endDate).toLocaleDateString()}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text>Certificate content :</Text>
                    <Text isBold>{cert.body}</Text>
                  </Flex>

                  <Flex>
                    <Text>Certificate issued by :</Text>
                    <Text isBold>{cert.doctor.username}</Text>
                  </Flex>
                </List>
              </Card>
            ))}
      </Card>
      {isModalOpen && (
        <div className="fixed inset-0 backdrop-brightness-50 flex items-center justify-center z-100">
          <div
            className="bg-white p-8 rounded-lg w-[60%] h-[90%]"
            ref={modalRef}
          >
            <form className="w-full" onSubmit={sendMC}>
              <div className="flex flex-col mb-4">
                <label className="mb-2" htmlFor="fromDate">
                  From:
                </label>
                <input
                  required
                  className="border p-2"
                  id="fromDate"
                  type="date"
                  onChange={e => setFromDate(e.target.value)}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-2" htmlFor="toDate">
                  To:
                </label>
                <input
                  required
                  className="border p-2"
                  id="toDate"
                  type="date"
                  onChange={e => setToDate(e.target.value)}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-2" htmlFor="unfitFor">
                  Unfit for:
                </label>
                <input
                  required
                  className="border p-2"
                  id="unfitFor"
                  type="text"
                  value={unfitFor}
                  onChange={e => setUnfitFor(e.target.value)}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-2" htmlFor="title">
                  Title:
                </label>
                <input
                  required
                  className="border p-2"
                  id="title"
                  type="text"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-2" htmlFor="body">
                  Body:
                </label>
                <textarea
                  required
                  className="border p-2 resize-none"
                  id="body"
                  rows={6}
                  value={body}
                  onChange={e => setBody(e.target.value)}
                />
              </div>

              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded block disabled:cursor-not-allowed disabled:bg-gray-500"
                type="submit"
                disabled={
                  fromDate.length === 0 ||
                  toDate.length === 0 ||
                  title.trim().length === 0 ||
                  body.trim().length === 0 ||
                  unfitFor.trim().length === 0 ||
                  isLoading
                }
              >
                Send
              </button>
              {errorMessage.trim().length > 0 && (
                <Text isBold className="text-red-600">
                  {errorMessage}
                </Text>
              )}
            </form>
          </div>
        </div>
      )}
    </article>
  )
}

export default McGenerator
