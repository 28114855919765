import classNames from 'classnames'
import { forwardRef, useCallback, useId } from 'react'
import ReactSelect, { components, Props } from 'react-select'

import { FormField, FormFieldProps } from '../formField/FormField'
import { FloatingLabel } from '../label/FloatingLabel'

export type SelectOption<T extends any> = {
  value: T
  label: string
}

export type SelectProps<T extends any = any> = Pick<
  Props,
  | 'defaultValue'
  | 'placeholder'
  | 'isSearchable'
  | 'isMulti'
  | 'isClearable'
  | 'isDisabled'
  | 'isOptionDisabled'
  | 'value'
> &
  Pick<FormFieldProps, 'color'> & {
    isFloatingLabel?: boolean
    isLabel?: boolean
    label?: string
    message?: string
    options: SelectOption<T>[]
    onChange?: (item: SelectOption<T>) => void
    onChangeMultiple?: (item: SelectOption<T>[]) => void
  }

function SelectInner<T extends any>(
  {
    isClearable = false,
    isSearchable = false,
    isFloatingLabel = true,
    label,
    message,
    placeholder,
    value,
    isMulti,
    color,
    onChange,
    onChangeMultiple,
    ...props
  }: SelectProps<T>,
  // @ts-ignore
  ref,
) {
  const id = useId()

  const handleChange = useCallback(
    (newValue: unknown) => {
      if (Array.isArray(newValue)) {
        onChangeMultiple?.(newValue as SelectOption<T>[])
        return
      }

      onChange?.(newValue as SelectOption<T>)
    },
    [onChange, onChangeMultiple],
  )

  return (
    <FormField
      color={color}
      label={!isFloatingLabel ? label : undefined}
      message={message}
    >
      <ReactSelect
        blurInputOnSelect
        closeMenuOnSelect
        classNamePrefix="react-select"
        inputId={id}
        instanceId={id}
        isClearable={isClearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        placeholder={placeholder || 'Select...'}
        ref={ref}
        value={value || null}
        components={{
          Control: ({ children, ...props }) => {
            return (
              <components.Control {...props} className={props.className}>
                {children}
              </components.Control>
            )
          },
          ValueContainer: ({ children, ...props }) => {
            const { hasValue, selectProps } = props

            return (
              <components.ValueContainer {...props}>
                {isFloatingLabel && label && (
                  <FloatingLabel
                    className={classNames(color === 'danger' && 'text-red-600')}
                    htmlFor={id}
                    isFloating={hasValue || !!selectProps.inputValue}
                  >
                    {label}
                  </FloatingLabel>
                )}
                {children}
              </components.ValueContainer>
            )
          },
        }}
        styles={{
          valueContainer: (base, { hasValue, selectProps }) => {
            return {
              ...base,
              padding:
                (hasValue || selectProps.inputValue) && isFloatingLabel && label
                  ? '1.5rem 1rem 0.5rem 1rem'
                  : '1rem',
              position: 'relative',
            }
          },
          singleValue: base => {
            return {
              ...base,
              margin: '0',
            }
          },
          input: base => {
            return {
              ...base,
              margin: '0',
              padding: '0',
            }
          },
          control: (base, { menuIsOpen, isDisabled }) => {
            const getBorderColor = () => {
              if (menuIsOpen) {
                return '#000000'
              }

              if (color === 'danger') {
                return 'rgb(220 38 38)'
              }

              return 'rgb(156 163 175)'
            }

            return {
              ...base,
              backgroundColor: isDisabled
                ? 'rgb(229 231 235)'
                : base.backgroundColor,
              cursor: isDisabled ? 'not-allowed' : undefined,
              borderBottomLeftRadius: menuIsOpen ? '0' : undefined,
              borderBottomRightRadius: menuIsOpen ? '0' : undefined,
              boxShadow: 'none',
              borderColor: getBorderColor(),
              ':hover': {
                borderColor: getBorderColor(),
              },
            }
          },
          container: (base, { isDisabled }) => {
            return {
              ...base,
              pointerEvents: isDisabled ? 'auto' : undefined,
            }
          },
          placeholder: base => {
            return {
              ...base,
              margin: '0',
              display: isFloatingLabel && label ? 'none' : undefined,
              color: 'rgb(156 163 175)',
            }
          },
          dropdownIndicator: base => {
            return {
              ...base,
              padding: '0 0.5rem',
              marginRight: '0.25rem',
            }
          },
          clearIndicator: base => {
            return {
              ...base,
              padding: '0 0.5rem',
            }
          },
          indicatorSeparator: base => {
            return {
              ...base,
              display: 'none',
            }
          },
          menu: base => {
            return {
              ...base,
              margin: '0',
              boxShadow: 'none',
              border: `1px solid #000000`,
              borderTop: 'none',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
              transform: 'translateY(-1px)',
            }
          },
          menuList: base => {
            return {
              ...base,
              padding: '0',
            }
          },
          noOptionsMessage: base => {
            return {
              ...base,
              padding: '1rem',
              textAlign: 'left',
            }
          },
          option: base => {
            return {
              ...base,
              padding: '1rem',
            }
          },
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
          },
        })}
        onChange={handleChange}
        {...props}
      />
    </FormField>
  )
}

export const Select = forwardRef(SelectInner)
