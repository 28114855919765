import classNames from 'classnames'
import { FC, PropsWithChildren } from 'react'

type SectionProps = {
  padding?: 'py-0' | 'py-4' | 'py-8' | 'py-20'
  className?: string
}

export const Section: FC<PropsWithChildren<SectionProps>> = ({
  padding = 'py-0',
  className,
  children,
}) => {
  return (
    <section className={classNames(padding, className)}>{children}</section>
  )
}
