import { useMutation, useQueryClient } from '@tanstack/react-query'

import { voidPrescription } from './voidPrescription'

export const useVoidMedicalPrescriptionsMutation = () => {
  const queryClient = useQueryClient()

  const { error, mutate, ...mutation } = useMutation(voidPrescription, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prescriptions'] })
    },
  })

  return {
    error,
    mutate,
    ...mutation,
  }
}
