import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { sendEmail } from './sendEmail'

export const useSendEmail = () => {
  const { error, mutate, ...mutation } = useMutation(sendEmail, {
    onSuccess: () => {
      toast.success('Prescription emailed to user')
    },
    onError: () => {
      toast.error('Something went wrong')
    },
    retry: false,
  })

  return {
    error,
    mutate,
    ...mutation,
  }
}
