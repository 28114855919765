import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'

export type H2Props = HTMLAttributes<HTMLHeadingElement>

export const H2: FC<PropsWithChildren<H2Props>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <h2 className={classNames('text-4xl font-extrabold', className)} {...props}>
      {children}
    </h2>
  )
}
