/* eslint-disable no-undef */
import classNames from 'classnames'
import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  RefAttributes,
  useEffect,
  useId,
  useState,
} from 'react'

import { ControlBorderRadius } from '../../types'
import { FormField, FormFieldProps } from '../formField/FormField'
import { FloatingLabel } from '../label/FloatingLabel'

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  FormFieldProps &
  RefAttributes<HTMLInputElement> & {
    rightLabel?: ReactNode
    isFullWidth?: boolean
    isFloatingLabel?: boolean
    isMinWidth?: boolean
    textAlign?: 'text-center' | 'text-right'
    borderRadius?: ControlBorderRadius
  }

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      rightLabel,
      secondaryLabel,
      message,
      isFullWidth = true,
      isMinWidth = true,
      isFloatingLabel = true,
      color,
      className,
      placeholder = '',
      textAlign,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const id = useId()
    const [isValue, setIsValue] = useState(true)

    useEffect(() => {
      // TODO:
      // haven't figure out what's the best way to handle
      // floating label with autofill when navigate using browser back/forward.
      // This little hack is not elegant but does the job.
      // Please fix this if you have better idea.
      setIsValue(!!(document.getElementById(id) as HTMLInputElement)?.value)
    }, [id])

    return (
      <FormField
        color={color}
        label={!isFloatingLabel ? label : undefined}
        message={message}
        secondaryLabel={!isFloatingLabel ? secondaryLabel : undefined}
      >
        <div>
          <input
            data-testid="input"
            id={id}
            placeholder={placeholder}
            ref={ref}
            type="text"
            className={classNames(
              'ui-input peer input px-[0.875rem] outline-none transition-colors',
              'disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 placeholder:text-gray-400',
              'appearance-none',
              'autofill:text-base',
              'focus:border-black',
              'border-gray-400 border py-[0.875rem] h-[52px]',
              isFloatingLabel &&
                label &&
                'placeholder-shown:pt-[1.375rem] placeholder-shown:pb-1 autofill:pt-[1.375rem] autofill:pb-1 focus:pt-[1.375rem] focus:pb-1',
              color === 'danger' &&
                'border-red-600 text-red-600 focus:border-red-600',
              isFullWidth && 'w-full',
              isMinWidth && 'min-w-[200px]',
              isValue &&
                isFloatingLabel &&
                label &&
                'is-value pt-[1.375rem] pb-1',
              textAlign,
              className,
            )}
            onBlur={event => {
              setIsValue(!!event.target.value)
              onBlur?.(event)
            }}
            {...props}
          />

          {isFloatingLabel && label && (
            <FloatingLabel
              className={color === 'danger' ? 'text-red-600' : undefined}
              htmlFor={id}
            >
              {label}
            </FloatingLabel>
          )}

          {rightLabel && (
            <div className="text-gray-600 absolute top-1/2 right-4 -translate-y-1/2">
              {rightLabel}
            </div>
          )}
        </div>
      </FormField>
    )
  },
)

Input.displayName = 'Input'
