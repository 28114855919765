import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'

export type PProps = HTMLAttributes<HTMLParagraphElement> & {
  size?: 'small' | 'large'
  isBold?: boolean
}

export const Text: FC<PropsWithChildren<PProps>> = ({
  size,
  isBold = false,
  children,
  className,
  ...props
}) => {
  return (
    <>
      <span
        className={classNames(
          'ui-text',
          size === 'small' && 'text-sm',
          size === 'large' && 'text-lg',
          isBold && 'font-bold',
          className,
        )}
        {...props}
      >
        {children}
      </span>
    </>
  )
}
