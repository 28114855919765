import classNames from 'classnames'
import { forwardRef, InputHTMLAttributes, ReactNode, useId } from 'react'

import { Flex } from '../../layout'
import { FormField } from '../formField/FormField'
import { Label } from '../label/Label'

export type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  message?: ReactNode
  color?: 'danger'
  disabled?: boolean
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ message, className, color, disabled, children, ...props }, ref) => {
    const id = useId()

    return (
      <FormField color={color} message={message}>
        <Label
          isMarginless
          className={classNames('cursor-pointer')}
          htmlFor={id}
          isBold={false}
        >
          <Flex gap="gap-2">
            <Flex
              className="relative"
              gap="gap-2"
              justifyContent="justify-center"
            >
              <input
                data-testid="radio"
                id={id}
                ref={ref}
                type="radio"
                className={classNames(
                  'peer border-neutral4 h-5 w-5 cursor-pointer appearance-none rounded-full border bg-white shadow-sm transition-all',
                  'disabled:bg-neutral3 disabled:cursor-not-allowed disabled:border-neutral3',
                  'focus:border-teal2 focus:outline-none',
                  'checked:border-teal2  checked:disabled:opacity-40',
                  color === 'danger' && 'border-red3',
                  className,
                )}
                {...props}
              />

              <div
                className={classNames(
                  'absolute rounded-full h-3 w-3',
                  disabled
                    ? 'peer-checked:bg-white cursor-not-allowed'
                    : 'peer-checked:bg-teal2',
                )}
              />
            </Flex>

            <span>{children}</span>
          </Flex>
        </Label>
      </FormField>
    )
  },
)

Radio.displayName = 'Radio'
