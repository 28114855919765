import { createAxiosInstance, getToken } from '..'
import { MedicalCertificates } from './types'

export const getMedicalCertificates = async () => {
  const accessToken = getToken({ fromQueryParams: false })

  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const { data } = await axiosInstance.get<MedicalCertificates>(
    `/users/medical-certificates/current`,
  )
  return data.medicalCertificates
}
