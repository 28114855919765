import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'

export type GridProps = HTMLAttributes<HTMLElement> & {
  gap?: 'gap-0' | 'gap-1' | 'gap-2' | 'gap-4' | 'gap-6' | 'gap-8' | 'gap-10'
  cols?:
    | 'grid-cols-1'
    | 'grid-cols-2'
    | 'grid-cols-3'
    | 'grid-cols-4'
    | 'grid-cols-5'
    | 'grid-cols-6'
    | 'grid-cols-7'
    | string
  justifyContent?: 'justify-center'
  alignItems?: 'items-start' | 'items-center' | 'items-end'
  justifyItem?: 'justify-items-center'
  isAutoRow?: boolean
}

export const Grid: FC<PropsWithChildren<GridProps>> = ({
  gap = 'gap-6',
  cols,
  justifyContent,
  alignItems,
  justifyItem,
  isAutoRow,

  className,
  children,
  ...props
}) => {
  return (
    <div
      data-testid="grid"
      className={classNames(
        'grid auto-rows-auto',
        isAutoRow && 'auto-rows-fr',
        cols,
        gap,
        justifyContent,
        alignItems,
        justifyItem,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
