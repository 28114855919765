import { VisitStatus } from '../../services/medical/types'
import { Card, Flex, List, Text } from '../../ui'
import { usePlatoContext } from './PlatoProvider'

export const ConsultationStatus = () => {
  const { visit, status } = usePlatoContext()
  const { reason } = visit
  return (
    <Card className="px-6 text-xl" padding="p-0">
      <List>
        <Text isBold>Consultation Status</Text>
        <Flex className="text-xl">
          <Text isBold>Consultation Status:</Text>
          <Text className="capitalize">{status}</Text>
        </Flex>
        {status === VisitStatus.Rejected && (
          <Flex className="text-xl">
            <Text isBold>Rejection Reason:</Text>
            <Text>{reason}</Text>
          </Flex>
        )}
      </List>
    </Card>
  )
}
