import { createAxiosInstance, setToken } from '..'
import { AuthorizationResponse } from './types'

export const authorize = async (platoToken: string) => {
  const axiosInstance = await createAxiosInstance({ token: platoToken })
  const { data } = await axiosInstance.get<AuthorizationResponse>(`/plato/auth`)

  // store the token in local storage before we move on.
  // this is what we'll pass around as we deal with the different endpoints on ORA.
  setToken(data.accessToken)

  return data
}
