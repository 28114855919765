import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

import { Card, Flex, List, Text } from '../../ui'
import { TreatmentProduct, useTreatmentContext } from './TreatmentProvider'

type TreatmentPlanItemProps = {
  item: TreatmentProduct
}
export const TreatmentPlanItem: FC<TreatmentPlanItemProps> = ({ item }) => {
  const { getDescription, increaseQuantity, decreaseQuantity } =
    useTreatmentContext()

  const isOneTimePurchase = !item.plan.recurring?.interval

  return (
    <Card padding="p-0">
      <List
        alignItems="items-center"
        gap="gap-2"
        justifyContent="justify-between"
      >
        <List gap="gap-0">
          <Text size="small">{item.plan.name}</Text>
          <em className="text-xs">
            <Text className="text-xs">{getDescription(item.plan)}</Text>
          </em>
        </List>
        <Flex
          alignItems="items-center"
          className="w-full"
          justifyContent="justify-between"
        >
          <Text className="mt-1" size="small">
            ${item.quantity * item.plan?.price?.value}
          </Text>
          <Flex
            alignItems="items-end"
            className="w-full"
            justifyContent="justify-end"
          >
            {isOneTimePurchase && (
              <button className="flex" onClick={() => increaseQuantity(item)}>
                <Text className="text-red3" size="small">
                  <FontAwesomeIcon icon={faCirclePlus} />
                </Text>
              </button>
            )}
            <Text size="small">{item.quantity}</Text>
            <button className="flex" onClick={() => decreaseQuantity(item)}>
              <Text className="text-red3" size="small">
                <FontAwesomeIcon icon={faCircleMinus} />
              </Text>
            </button>
          </Flex>
        </Flex>
      </List>
    </Card>
  )
}
