import { createAxiosInstance, getToken } from '..'

export type SelectedTreatmentProduct = {
  plan: {
    id: string
    refId: string
  }
  quantity: number
  slug: string
  stringRecord: string
}

export type CreateTreatmentContext = {
  category: string
  orderId: string
  products: SelectedTreatmentProduct[]
}

export const createTreatment = async ({
  category,
  orderId,
  products,
}: CreateTreatmentContext): Promise<boolean> => {
  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })
  if (products.length === 0) {
    return false
  }
  const { data } = await axiosInstance.post<boolean>('/users/me/treatments', {
    metadata: {
      category,
      orderId,
    },
    products,
  })

  return data
}
