import { useQuery } from '@tanstack/react-query'

import { getProducts } from '../../services'
import { Brand } from '../../services/medical/types'

export const useGetProductsQuery = (category: string, brand: Brand) => {
  const query = useQuery({
    queryKey: ['products', category],
    queryFn: () => getProducts(category, brand),
  })

  return {
    ...query,
    products: query.data,
  }
}
