import { createAxiosInstance, getToken } from '..'

export const voidPrescription = async (prescriptionId: string) => {
  const accessToken = getToken({ fromQueryParams: false })

  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const { data } = await axiosInstance.delete<boolean>(
    `/prescriptions/${prescriptionId}`,
  )

  return data
}
