import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react'

import { Box, BoxProps } from '../box/Box'

export type CardProps = HTMLAttributes<HTMLElement> &
  Pick<BoxProps, 'padding'> & {
    boxClassName?: string
    header?: ReactNode
    footer?: ReactNode
    isScrollable?: boolean
    isSquare?: boolean
    isShadow?: boolean
    maxHeight?: 'max-h-[75vh]'
  }

export const Card: FC<PropsWithChildren<CardProps>> = ({
  padding = 'p-6',
  isScrollable = false,
  isShadow = false,
  isSquare = false,
  className,
  children,
  maxHeight,
  boxClassName,
  ...props
}) => {
  return (
    <article
      data-testid="card"
      className={classNames(
        'rounded-lg',
        isShadow && 'shadow',
        isSquare && 'rounded-none',
        className,
      )}
      {...props}
    >
      <Box
        as="section"
        padding={padding}
        className={classNames(
          'h-full',
          isScrollable && 'overflow-y-auto',
          maxHeight,
          boxClassName,
        )}
      >
        {children}
      </Box>
    </article>
  )
}
