import { createAxiosInstance, getToken } from '..'
import { Product } from '../products/types'

export type GetTreatmentContext = {
  treatmentId: string
}

export type Treatment = {
  cartId: string
  id: string
  metadata: {
    category: string
  }
  products: Product[]
}

export const getTreatment = async ({
  treatmentId,
}: GetTreatmentContext): Promise<Treatment | undefined> => {
  if (!treatmentId) {
    return undefined
  }

  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })

  const { data } = await axiosInstance.get<Treatment>(
    `/treatments/${treatmentId}`,
  )

  return data
}
