import { createAxiosInstance, getToken } from '..'
import { Order } from './types'

export type FinalizeConsultationContext = {
  consultationType: 'customer-pick' | 'treatment-plan'
  orderRef: string
  order: Order
  reason: string
  status: boolean
}

export const finalizeConsultation = async ({
  consultationType,
  orderRef,
  order,
  reason,
  status,
}: FinalizeConsultationContext): Promise<boolean> => {
  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const context = {
    consultationType,
    orderRef,
    order,
    status,
    reason,
  }

  if (!status && !reason) {
    throw Error('Please enter a reason for rejection')
  }

  const { data } = await axiosInstance.post<boolean>(
    '/consultations/current/finalize',
    context,
  )

  return data
}
