import axios from 'axios'

import { getClientConfig } from '../../config/configs'
import { Config } from '../../config/types'
import { getToken, removeUndefined } from '../'
import { DEVICE_ID_ALIAS, getDeviceId } from './deviceId'
import { getSessionId, SESSION_ID_ALIAS } from './sessionId'

type CreateAxiosInstanceParams = {
  baseURL?: string
  token?: string
}

export const createAxiosInstance = async (
  options?: CreateAxiosInstanceParams,
) => {
  const { api } = getClientConfig() as Config

  const authorization = options?.token
    ? `Bearer ${options.token}`
    : getToken({ fromQueryParams: false })

  const { baseURL } = options || {}

  const deviceId = getDeviceId()
  const sessionId = getSessionId()

  return axios.create({
    baseURL: baseURL || api.baseURL,
    headers: {
      ...removeUndefined({
        Authorization: authorization,
        [DEVICE_ID_ALIAS]: deviceId,
        [SESSION_ID_ALIAS]: sessionId,
      }),
    },
    timeout: api.timeout,
  })
}
