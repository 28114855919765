import { createAxiosInstance, getToken } from '..'

export type StartConsultationContext = {
  orderRef: string
}

export const startConsultation = async ({
  orderRef,
}: StartConsultationContext): Promise<boolean> => {
  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const context = {
    orderRef,
  }

  const { data } = await axiosInstance.post<boolean>(
    `/orders/${orderRef}/appointments/start`,
    context,
  )

  return data
}
