import {
  faEnvelope,
  faPrint,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { useVoidMedicalPrescriptionsMutation } from '../../services'
import { useSendEmail } from '../../services'
import { MedicalPrescriptionMetadata } from '../../services/medical/getMedicalPrescriptions'
import { Flex, Text } from '../../ui'

export type UtilityBarProps = {
  prescription: MedicalPrescriptionMetadata
  prescriptionIndex: number
}
export const UtilityBar: FC<UtilityBarProps> = ({
  prescription,
  prescriptionIndex,
}) => {
  const { mutate: voidPrescription } = useVoidMedicalPrescriptionsMutation()
  const { mutate: sendEmail, isLoading: sendingEmail } = useSendEmail()
  const deletePrescription = () => {
    if (!prescription) {
      return
    }
    voidPrescription(prescription.id)
  }

  const emailPrescriptionToUser = () => {
    if (!prescription) {
      return
    }
    sendEmail(prescription.id)
  }

  const prescriptionDate =
    prescription && new Date(prescription?.timestampCreated)
  return (
    <>
      {sendingEmail && (
        <div className="overlay flex flex-col">
          <div className="spinner" />
          <Text isBold className="text-black" size="large">
            Sending Email...
          </Text>
        </div>
      )}
      <Flex
        alignItems="items-center"
        className="mb-4"
        justifyContent="justify-between"
      >
        {prescription && (
          <Flex gap="gap-6">
            <Text className="uppercase font-mono">
              Prescription ID: {prescription?.id}
            </Text>
            <Text className="uppercase font-mono">
              Issued on:{' '}
              {`${prescriptionDate?.toLocaleDateString()} ${prescriptionDate?.toLocaleTimeString()}`}
            </Text>
            <Text className="uppercase font-mono">
              Issued by:{' '}
              {`${prescription.doctor.username} (${prescription.doctor.mcr})`}
            </Text>
          </Flex>
        )}
        {(!prescription || prescription.status === 'void') && (
          <Text className="uppercase font-mono">
            Prescription status: {prescription?.status ?? 'Draft'}
          </Text>
        )}
        {prescription && prescription.status !== 'void' && (
          <Flex alignItems="items-center" justifyContent="justify-end">
            <button
              className="border-0 shadow-0 p-0 m-0"
              type="button"
              onClick={deletePrescription}
            >
              <FontAwesomeIcon
                className="text-2xl"
                color="red"
                icon={faTrashAlt}
              />
            </button>

            <Link to={`/print/${prescription.id}?index=${prescriptionIndex}`}>
              <FontAwesomeIcon className="text-2xl" icon={faPrint} />
            </Link>
            <button
              className="border-0 shadow-0 p-0 m-0"
              type="button"
              onClick={emailPrescriptionToUser}
            >
              <FontAwesomeIcon className="text-2xl" icon={faEnvelope} />
            </button>
          </Flex>
        )}
      </Flex>
    </>
  )
}
