import { Card, Flex, List, Text } from '../../ui'
import { useTreatmentContext } from './TreatmentProvider'

export const TreatmentPlanSummary = () => {
  const { treatmentPrice } = useTreatmentContext()
  return (
    <Card className="bg-neutral6">
      <List>
        <Flex alignItems="items-center">
          <Text isBold size="small">
            Total Price
          </Text>
          <Flex className="grow" />
          <Text isBold className="text-right" size="small">
            ${treatmentPrice.toFixed(2)}
          </Text>
        </Flex>
      </List>
    </Card>
  )
}
