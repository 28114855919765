import { useQuery } from '@tanstack/react-query'

import { getMedicalCertificates } from '../../services'

export const useGetMc = () => {
  const query = useQuery({
    queryKey: ['medicalCertificates'],
    queryFn: () => getMedicalCertificates(),
    retry: false,
  })

  return {
    ...query,
    certificates: query.data || [],
  }
}
