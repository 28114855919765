import { createAxiosInstance, getToken } from '..'
import { OrderCategory } from './types'

type BriefUser = {
  email: string
  id: string
  name: string
  nric: string
  universalUserId: string
}

export type Doctor = {
  email: string
  username: string
}

export type PrescriptionProduct = {
  isSubscription?: boolean
  instructions: {
    frequency: string
    remarks?: string
    quantityPrescribed?: string
  }
  name: string
  slug: string
  requiresPrescription?: boolean
  category?: OrderCategory
  status?: 'approved' | 'draft' | 'void'
  type?: 'general-consultation'
  refProductId?: number
}

export type MedicalPrescriptionMetadata = {
  doctor: Doctor & { signatureURL: string; mcr: string }
  id: string
  products: PrescriptionProduct[]
  status: string
  timestampCreated: number
  timestampExpiration: number
  user: BriefUser
}

export type MedicalPrescriptions = {
  prescriptions: MedicalPrescriptionMetadata[]
  requestId: string
}

export const getMedicalPrescriptions = async () => {
  const accessToken = getToken({ fromQueryParams: false })

  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const { data } = await axiosInstance.get<MedicalPrescriptions>(
    `/prescriptions/current`,
  )

  return data
}
