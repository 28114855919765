import { getSessionStorage, removeSessionStorage, setSessionStorage } from '../'

export const SESSION_ID_ALIAS = 'x-sid'

export const setSessionId = (sessionId: string) => {
  setSessionStorage(SESSION_ID_ALIAS, sessionId)
}

export const removeSessionId = () => {
  removeSessionStorage(SESSION_ID_ALIAS)
}

export const getSessionId = () => {
  return getSessionStorage<string>(SESSION_ID_ALIAS)
}
