import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'

export type H3Props = HTMLAttributes<HTMLHeadingElement>

export const H3: FC<PropsWithChildren<H3Props>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <h3 className={classNames('text-3xl font-extrabold', className)} {...props}>
      {children}
    </h3>
  )
}
