import { isBrowser } from '../services'
import development from './development.json'
import production from './production.json'
import staging from './staging.json'
import { Config, Environment } from './types'

// type AppName = 'admin-web'
// type Version = '1.0.0'

const configMap: Record<Environment, Config> = {
  development,
  production,
  staging,
}

export function getEnvironmentByHostname(hostname: string): Environment {
  if (hostname.includes('local')) {
    return 'development'
  }

  if (hostname.includes('staging')) {
    return 'staging'
  }

  return 'production'
}

export function getHostnameByOrigin(origin?: string) {
  if (!origin?.includes('://')) {
    return
  }

  return origin
    ?.replace(':3000', '')
    .replace('https://', '')
    .replace('http://', '')
}
export function getConfig(origin?: string) {
  if (!origin) {
    return
  }

  let hostname = getHostnameByOrigin(origin)

  if (!hostname) {
    return
  }

  if (hostname.includes('ngrok')) {
    hostname = 'ora-staging.com'
  }
  const environment = getEnvironmentByHostname(hostname)

  return configMap[environment]
}

export function getClientConfig() {
  if (!isBrowser()) {
    return
  }

  // eslint-disable-next-line no-undef
  return getConfig(location.origin)
}
