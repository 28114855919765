import classNames from 'classnames'
import { FC } from 'react'

import { Card, List, Text } from '../../ui'
import { TreatmentPlanActions } from './TreatmentPlanActions'
import { TreatmentPlanItem } from './TreatmentPlanItem'
import { TreatmentPlanSummary } from './TreatmentPlanSummary'
import { useTreatmentContext } from './TreatmentProvider'

type TreatmentPlanProps = {
  className?: string
}
export const TreatmentPlan: FC<TreatmentPlanProps> = ({ className }) => {
  const { treatment } = useTreatmentContext()

  return (
    <Card className={classNames(className)} padding="p-0">
      <List>
        <Text isBold>Treatment Plan</Text>
        <Card className="bg-neutral6">
          <List gap="gap-2">
            {treatment && treatment.length === 0 && (
              <Text isBold size="small">
                Start adding products to treatment
              </Text>
            )}
            {treatment &&
              treatment.length > 0 &&
              treatment.map((item, index) => (
                <TreatmentPlanItem key={index} item={item} />
              ))}
          </List>
        </Card>
        <TreatmentPlanSummary />
        <TreatmentPlanActions />
      </List>
    </Card>
  )
}
