import { createAxiosInstance, getToken } from '..'

export type createMedicalCertificateContext = {
  body: string
  category: string
  startDate: string
  endDate: string
  title: string
}

export const createMedicalCertificate = async (
  context: createMedicalCertificateContext,
): Promise<boolean> => {
  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })

  const { data } = await axiosInstance.post<boolean>(
    '/users/me/medical-certificates',
    context,
    { timeout: 20000 },
  )

  return data
}
