import { useMutation } from '@tanstack/react-query'

import { createTreatment } from '../../services'

export const useCreateTreatmentMutation = () => {
  const { error, mutate, ...mutation } = useMutation(createTreatment)

  return {
    error,
    mutate,
    ...mutation,
  }
}
