declare global {
  interface Array<T> {
    sortDescendingBy(key: keyof T): T[]
    sortAscendBy(key: keyof T): T[]
    groupBy(
      key: keyof T | ((item: T) => string),
    ): { group: string; values: T[] }[]
    pluck(key: keyof T): T[keyof T][]
    addField<K>(key: string, fn: (item: T) => K): (T & { [key: string]: K })[]
    sum(): number
    max(): number
  }
}

export const defaultToEmptyArray = <T>(value: T[] | undefined): T[] => {
  return value || []
}

Array.prototype.groupBy = function <T>(
  this: T[],
  key: keyof T | ((item: T) => string),
) {
  return Object.entries(
    this.reduce(function (groups, item) {
      const val = String(key instanceof Function ? key(item) : item[key])

      groups[val] = [...(groups[val] || []), item]

      return groups
    }, {} as Record<string, T[]>),
  ).map(([group, values]) => ({ group, values }))
}

Array.prototype.sortDescendingBy = function <T>(this: T[], key: keyof T) {
  return this.sort((a, b) => -String(a[key]).localeCompare(String(b[key])))
}

Array.prototype.sortAscendBy = function <T>(this: T[], key: keyof T) {
  return this.sort((a, b) => String(a[key]).localeCompare(String(b[key])))
}

Array.prototype.addField = function <T, K>(
  this: T[],
  key: string,
  fn: (item: T) => K,
) {
  return this.map(item => ({ ...item, [key]: fn(item) }))
}

Array.prototype.pluck = function <T>(this: T[], key: keyof T) {
  return this.map(item => item[key])
}

Array.prototype.sum = function (this: number[]) {
  return this.reduce((output, current) => output + current, 0)
}

Array.prototype.max = function (this: number[]) {
  return Math.max(...this)
}

export const generateArray = (num: number) => {
  return Array.from(Array(Math.max(num, 0)).keys())
}
