import classNames from 'classnames'
import { FC } from 'react'

// import Select from 'react-select'
// import { Brand } from '../../services/medical/types'
import { Card, Grid, H4, List } from '../../ui'
import { usePlatoContext } from '../Plato/PlatoProvider'
import { ProductCard } from './ProductCard'
// import { useProductsContext } from './ProductsProvider'
import { TreatmentPlan } from './TreatmentPlan'
import { useTreatmentContext } from './TreatmentProvider'

// const CATEGORIES = {
//   [Brand['andsons-sg']]: [
//     'hair-loss',
//     'sexual-health',
//     'skincare',
//     'well-being',
//   ],
//   [Brand['ova-sg']]: [
//     'birth-control',
//     'emergency-contraception',
//     'skincare',
//     'nausea',
//     'yeast-infection',
//     'pms',
//     'sti',
//     'period-delay',
//   ],
// }

type CartBuilderProps = {
  className?: string
}

export const CartBuilder: FC<CartBuilderProps> = ({ className }) => {
  const { brand } = usePlatoContext()
  const { products } = useTreatmentContext()
  // const { category, setCategory } = useProductsContext()

  // const createCategoriesSelection = () => {
  //   const categories = CATEGORIES[brand].map(category => {
  //     return {
  //       label: category.toUpperCase(),
  //       value: category,
  //     }
  //   })
  //   return categories
  // }

  return (
    <Card className={classNames('px-6 text-xl', className)} padding="p-0">
      <List gap="gap-4">
        <H4 className="uppercase">{brand} Categories</H4>
        {/* Removing till we figure out why products service is returning the wrong category
          <Select
            defaultValue={{ label: category.toUpperCase(), value: category }}
            isSearchable={true}
            name="category"
            options={createCategoriesSelection()}
            onChange={option => option && setCategory(option.value)}
          /> 
        */}
        <Grid cols="grid-cols-4">
          <List className="col-span-3" gap="gap-4">
            {/* <Text isBold>Product Categories</Text> */}

            <Grid
              alignItems="items-start"
              className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
            >
              {products &&
                products.map((product, index) => {
                  return (
                    <ProductCard key={index} product={product} />
                    // <List key={`${category}-${product.name}`}>
                    //   <ProductOption {...product} />
                    // </List>
                  )
                })}
            </Grid>
          </List>
          <TreatmentPlan className="col-span-1" />
        </Grid>
      </List>
    </Card>
  )
}
