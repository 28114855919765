import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../'

export const DEVICE_ID_ALIAS = 'x-did'

export const setDeviceId = (deviceId: string) => {
  setLocalStorage(DEVICE_ID_ALIAS, deviceId)
}

export const removeDeviceId = () => {
  removeLocalStorage(DEVICE_ID_ALIAS)
}

export const getDeviceId = () => {
  return getLocalStorage<string>(DEVICE_ID_ALIAS)
}
