import {
  ChangeEventHandler,
  CompositionEvent,
  FC,
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  useCallback,
} from 'react'

import { clamp } from '../../../services'
import { Input, InputProps } from './Input'

export type IntergerInputProps = InputProps

export const IntergerInput: FC<IntergerInputProps> = forwardRef(
  ({ onChange, min, max, ...props }, ref) => {
    const handleBeforeInput: FormEventHandler<HTMLInputElement> = useCallback(
      event => {
        const inputChar = (event as CompositionEvent<HTMLInputElement>).data

        if (inputChar && !/[0-9]/.test(inputChar)) {
          event.preventDefault()
        }
      },
      [],
    )

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      event => {
        if (!event.target.value && min) {
          event.target.value = String(min)
        }

        onChange?.(event)
      },
      [min, onChange],
    )

    const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
      event => {
        if (event.target.value) {
          const value = parseInt(event.target.value, 10)
          if (min && max) {
            event.target.value = String(
              clamp(
                value,
                parseInt(String(min), 10),
                parseInt(String(max), 10),
              ),
            )
          } else if (min) {
            event.target.value = String(
              Math.max(parseInt(String(min), 10), value),
            )
          } else if (max) {
            event.target.value = String(
              Math.min(parseInt(String(max), 10), value),
            )
          }
        }

        onChange?.(event)
      },
      [max, min, onChange],
    )

    return (
      <Input
        ref={ref}
        {...props}
        max={max}
        min={min}
        type="number"
        onBeforeInput={handleBeforeInput}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    )
  },
)

IntergerInput.displayName = 'IntergerInput'
