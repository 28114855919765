import classNames from 'classnames'
import { FC, PropsWithChildren, ReactNode } from 'react'

import { List } from '../../layout'
import { Text } from '../../typographies'
import { Label, LabelProps } from '../label/Label'

export type FormFieldProps = {
  label?: LabelProps['children']
  secondaryLabel?: LabelProps['children']
  color?: 'danger'
  message?: ReactNode
  htmlFor?: string
}

export const FormField: FC<PropsWithChildren<FormFieldProps>> = ({
  label,
  message,
  children,
  color,
  htmlFor,
}) => {
  return (
    <div>
      {label && (
        <Label
          className={classNames(color === 'danger' && 'text-red-600')}
          htmlFor={htmlFor}
        >
          {label}
        </Label>
      )}

      <List gap="gap-2">
        {children}
        {message && (
          <Text
            size="small"
            className={classNames(
              'leading-none transition-colors',
              color === 'danger' && 'text-red-600',
            )}
          >
            {message}
          </Text>
        )}
      </List>
    </div>
  )
}

FormField.displayName = 'FormField'
