import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'

import { Option, PrimitiveType } from '../../types'

export type ChipOption = Option<PrimitiveType>

export type ChipsProps<T> = {
  disabled?: boolean
  options: T[]
  selectedChip?: T
  onChange: (selectedChip: T) => void
}

export const Chips = <T extends ChipOption>({
  selectedChip,
  options,
  disabled,
  onChange,
}: ChipsProps<T>) => {
  return (
    <RadioGroup value={selectedChip} onChange={onChange}>
      <div
        className={classNames(
          'flex',
          disabled && 'cursor-not-allowed opacity-40',
        )}
      >
        {options.map(option => (
          <RadioGroup.Option
            key={String(option.value)}
            value={option}
            className={classNames(
              'cursor-pointer rounded py-1 px-3 transition-all',
              disabled && 'pointer-events-none',
              selectedChip?.value === option.value
                ? 'bg-teal2 text-white'
                : 'text-teal2',
            )}
          >
            {option.label}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
