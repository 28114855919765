import classNames from 'classnames'
import { FC, LabelHTMLAttributes } from 'react'
export type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  htmlFor?: string
  isBold?: boolean
  isMarginless?: boolean
}

export const Label: FC<LabelProps> = ({
  htmlFor,
  isBold,
  isMarginless = false,
  className,
  children,
  ...props
}) => {
  return (
    <label
      data-testid="label"
      htmlFor={htmlFor}
      className={classNames(
        'block leading-tight',
        isBold && 'font-bold',
        !isMarginless && 'mb-2',
        className,
      )}
      {...props}
    >
      {children}
    </label>
  )
}

Label.displayName = 'Label'
