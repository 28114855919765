import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useCreateMedicalPrescriptionsMutation } from '../../services'
import {
  MedicalPrescriptionMetadata,
  PrescriptionProduct,
} from '../../services/medical/getMedicalPrescriptions'
import { useDebounce } from '../../services/utils/debounce'
import { Button, Card, Flex, Grid, H3, H4, List, Text } from '../../ui'
import { usePrescriptionsContext } from './PrescriptionsProvider'
import { UtilityBar } from './UtilityBar'

const CONSULTATION_PRODUCT_NAME = 'Enter product name'
const CONSULTATION_PRODUCT_REMARKS = 'Type to add remarks'

type PrescriptionItemProps = {
  description: string
  isHeader?: boolean
  quantityPrescribed: string
  name: string
  slug: string
  status?: string
  showAddItemIcon?: boolean
  type?: string
}
const PrescriptionItem: FC<PrescriptionItemProps> = props => {
  const {
    patchRemarks,
    patchQuantity,
    removeFromPrescriptions,
    addConsultationProductToPrescriptions,
    patchProductName,
  } = usePrescriptionsContext()

  const {
    name,
    description,
    quantityPrescribed,
    slug,
    status,
    isHeader = false,
    showAddItemIcon,
    type,
  } = props

  // eslint-disable-next-line no-undef
  const updateRemarks = (event: any) => {
    patchRemarks(props.slug, event.target.innerText as string)
  }

  const updateQuantity = (event: any) => {
    patchQuantity(props.slug, event.target.innerText as string)
  }

  const updateProductName = (event: any) => {
    patchProductName(props.slug, event.target.innerText as string)
  }

  const debouncedUpdateRemarks = useDebounce(updateRemarks, 1000)
  const debouncedUpdateQuantity = useDebounce(updateQuantity, 1000)
  const debouncedUpdateProductName = useDebounce(updateProductName, 1000)
  return (
    <>
      <Grid cols="grid-cols-12 items-center">
        {type && type === 'general-consultation' && (
          <Text
            className="text-center col-span-2"
            contentEditable={status === 'draft'}
            isBold={isHeader}
            onInput={debouncedUpdateProductName}
          >
            {name}
          </Text>
        )}
        {!type && (
          <Text className="text-center col-span-2" isBold={isHeader}>
            {name}
          </Text>
        )}
        <Text
          className="text-center col-span-3"
          contentEditable={status === 'draft'}
          isBold={isHeader}
          onInput={debouncedUpdateQuantity}
        >
          {quantityPrescribed}
        </Text>
        <Text
          className={`${status === 'draft' ? 'col-span-6' : 'col-span-7'}`}
          contentEditable={status === 'draft'}
          dangerouslySetInnerHTML={{ __html: description }}
          isBold={isHeader}
          onInput={debouncedUpdateRemarks}
        />
        {status === 'draft' && (
          <Flex className="col-span-1">
            <button type="button" onClick={() => removeFromPrescriptions(slug)}>
              <FontAwesomeIcon
                className="text-2xl"
                color="red"
                icon={faTrashAlt}
              />
            </button>
            {showAddItemIcon && (
              <button
                type="button"
                onClick={() => {
                  const generalConsultationProduct: PrescriptionProduct = {
                    name: CONSULTATION_PRODUCT_NAME,
                    slug: uuidv4(),
                    isSubscription: false,
                    requiresPrescription: true,
                    status: 'draft',
                    instructions: {
                      quantityPrescribed: '0',
                      remarks: CONSULTATION_PRODUCT_REMARKS,
                      frequency: '0',
                    },
                    type: 'general-consultation',
                  }
                  addConsultationProductToPrescriptions(
                    generalConsultationProduct,
                  )
                }}
              >
                <FontAwesomeIcon
                  className="text-2xl"
                  color="green"
                  icon={faPlus}
                />
              </button>
            )}
          </Flex>
        )}
      </Grid>
      <hr />
    </>
  )
}

export const PrescriptionsList: FC = () => {
  const {
    currentPrescriptions: prescriptions,
    prescriptions: existingPrescriptions,
    addToPrescriptions,
  } = usePrescriptionsContext()
  const { mutate, isLoading } = useCreateMedicalPrescriptionsMutation()

  const createPrescriptions = () => {
    mutate({
      products: prescriptions.filter(product => product.status === 'draft'),
    })
  }

  const ExistingPrescriptionRecord: FC<{
    prescription: MedicalPrescriptionMetadata
    prescriptionIndex: number
  }> = ({ prescription, prescriptionIndex }) => {
    return (
      <>
        <Card isShadow className="bg-white">
          <UtilityBar
            prescription={prescription}
            prescriptionIndex={prescriptionIndex}
          />
          <List>
            <PrescriptionItem
              isHeader
              description="Remarks"
              name="Product"
              quantityPrescribed="Quantity Prescribed"
              slug="Actions"
              status="Status"
            />
            {prescription.products.map((item, index) => {
              return (
                <PrescriptionItem
                  key={index}
                  description={item.instructions.remarks || ''}
                  name={item.name}
                  slug={item.slug}
                  status={item.status}
                  quantityPrescribed={String(
                    item.instructions.quantityPrescribed || 1,
                  )}
                />
              )
            })}
          </List>
        </Card>
      </>
    )
  }

  return (
    <Card>
      <Flex className="mb-4" gap="gap-8">
        <H3>Prescriptions Details</H3>
        {prescriptions.length === 0 && (
          <Button
            className="bg-neutral6 text-base text-black"
            disabled={isLoading}
            size="small"
            type="button"
            onClick={() => {
              const generalConsultationProduct: PrescriptionProduct = {
                name: CONSULTATION_PRODUCT_NAME,
                slug: uuidv4(),
                isSubscription: false,
                requiresPrescription: true,
                status: 'draft',
                instructions: {
                  quantityPrescribed: '0',
                  remarks: CONSULTATION_PRODUCT_REMARKS,
                  frequency: '0',
                },
                type: 'general-consultation',
              }
              addToPrescriptions(generalConsultationProduct)
            }}
          >
            Add New Prescription
          </Button>
        )}
      </Flex>
      <H4 className="mb-4">Existing Prescriptions Details</H4>
      <List>
        {existingPrescriptions?.map((prescription, index) => {
          return (
            <ExistingPrescriptionRecord
              key={index}
              prescription={prescription}
              prescriptionIndex={index}
            />
          )
        })}
        {prescriptions.length > 0 && (
          <>
            <H4 className="mb-4">Current Prescriptions Details</H4>
            <Card isShadow className="bg-white">
              <List>
                <PrescriptionItem
                  isHeader
                  description="Remarks"
                  name="Product"
                  quantityPrescribed="Quantity Prescribed"
                  slug="Actions"
                  status="Status"
                />
                {prescriptions.map((item, index) => {
                  return (
                    <PrescriptionItem
                      key={index}
                      description={item.instructions.remarks || ''}
                      name={item.name}
                      slug={item.slug}
                      status="draft"
                      type={item.type}
                      quantityPrescribed={
                        item.instructions.quantityPrescribed || ''
                      }
                      showAddItemIcon={
                        item.type === 'general-consultation' &&
                        index === prescriptions.length - 1
                      }
                    />
                  )
                })}
                <Button
                  className="bg-steel1 text-base text-black"
                  disabled={isLoading}
                  type="button"
                  variant="primary"
                  onClick={createPrescriptions}
                >
                  Create Prescription
                </Button>
              </List>
            </Card>
          </>
        )}
      </List>
    </Card>
  )
}
