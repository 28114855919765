import { createAxiosInstance, getToken } from '..'

export const sendEmail = async (prescriptionId: string) => {
  const accessToken = getToken({ fromQueryParams: false })

  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const { data } = await axiosInstance.post<boolean>(
    `/prescriptions/${prescriptionId}/email`,
    undefined,
    {
      timeout: 20000,
    },
  )

  return data
}
