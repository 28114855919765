import { useQuery } from '@tanstack/react-query'

import { getMedicalPrescriptions } from './getMedicalPrescriptions'

export const usePrescriptionsQuery = () => {
  const query = useQuery({
    queryKey: ['prescriptions'],
    queryFn: getMedicalPrescriptions,
    retry: false,
  })

  return {
    ...query,
    prescriptions: query.data,
  }
}
