import { useQuery } from '@tanstack/react-query'

import { getTreatment } from '../../services'

export const useGetTreatmentQuery = (treatmentId: string) => {
  const query = useQuery({
    queryKey: ['treatment', treatmentId],
    queryFn: () => getTreatment({ treatmentId }),
    enabled: !!treatmentId,
  })

  return {
    ...query,
    treatment: query.data,
  }
}
