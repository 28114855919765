import { FC } from 'react'

import { Card, Flex, List, Text } from '../../ui'
import { usePlatoContext } from './PlatoProvider'

export const UserDetails: FC = () => {
  const { patient } = usePlatoContext()
  return (
    <Card>
      <List>
        <Flex className="text-xl">
          <Text isBold>Name:</Text>
          <Text>{patient.name}</Text>
        </Flex>
        <Flex className="text-xl">
          <Text isBold>NRIC:</Text>
          <Text>{patient.nric}</Text>
        </Flex>
      </List>
    </Card>
  )
}
