import classNames from 'classnames'
import { ButtonHTMLAttributes, forwardRef } from 'react'

export type Variant = 'primary' | 'secondary'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: Variant
  size?: 'small' | 'normal'
  isFullWidth?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      size = 'normal',
      disabled,
      isFullWidth,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        data-testid="button"
        disabled={disabled}
        ref={ref}
        type="button"
        className={classNames(
          'ui-button flex font-bold select-none items-center justify-center transition-all border uppercase',
          'hover:opacity-75',
          size === 'small' ? 'px-3 py-2' : 'py-[0.875rem] px-6',
          isFullWidth && 'w-full',
          disabled && 'disabled:cursor-not-allowed disabled:opacity-50',
          variant,
          className,
        )}
        {...props}
      >
        {children}
      </button>
    )
  },
)

Button.displayName = 'Button'
