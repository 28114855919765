/* eslint-disable no-undef */
import './index.css'

import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { VisitComponent } from './modules/Plato/VisitComponent'
import { PrescriptionsPrintout } from './modules/Prescriptions/PrescriptionsPrintout'
import reportWebVitals from './reportWebVitals'

const _JSXStyle = require('styled-jsx/style').default
if (typeof global !== 'undefined') {
  Object.assign(global, { _JSXStyle })
}

const App = lazy(() =>
  import('./App').then(module => ({ default: module.App })),
)

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <App title="Plato Plugin">
        <VisitComponent />
      </App>
    ),
  },
  {
    path: '/print/:id',
    element: (
      <App title="Plato Plugin">
        <PrescriptionsPrintout />
      </App>
    ),
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Suspense fallback={<h3>Loading...</h3>}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
