import { useQuery } from '@tanstack/react-query'

import { authorize, getToken } from '../../services'

export const useAuthorizationQuery = () => {
  const query = useQuery(
    ['userMetadata'],
    async () => {
      const platoToken = getToken({ fromQueryParams: true })

      if (!platoToken) {
        return undefined
      }

      const metadata = authorize(platoToken)
      return metadata
    },
    { staleTime: Infinity },
  )

  return {
    ...query,
    metadata: query.data,
  }
}
