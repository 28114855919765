import { FC } from 'react'

import { useToaster } from '../../hooks'
import { useStartConsultationMutation } from '../../queries'
import { Button } from '../../ui'
import { usePlatoContext } from './PlatoProvider'

export const StartConsultButton: FC = () => {
  const { order } = usePlatoContext()
  const { mutate, isLoading } = useStartConsultationMutation()
  const toast = useToaster()

  const onStartConsult = () =>
    mutate(
      {
        orderRef: order.orderRef,
      },
      {
        onSuccess: () => {
          toast.success('Consultation marked as started')
          return true
        },
      },
    )

  return (
    <Button
      isFullWidth
      className="bg-teal1Light text-base"
      disabled={isLoading}
      size="small"
      type="button"
      onClick={onStartConsult}
    >
      Log Start Consultation Call
    </Button>
  )
}
