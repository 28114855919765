/* eslint-disable no-undef */
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../'

export const TOKEN_KEY = 'token'

export const setToken = (token: string) => {
  setLocalStorage(TOKEN_KEY, token)
}

export const removeToken = () => {
  removeLocalStorage(TOKEN_KEY)
}

export type getTokenProps = {
  fromQueryParams?: boolean
}

export const getToken = ({ fromQueryParams = true }: getTokenProps) => {
  if (!fromQueryParams) {
    return getLocalStorage<string>(TOKEN_KEY)
  }

  let token = ''
  const params = new URLSearchParams(window.location.search)
  if (params.has(TOKEN_KEY)) {
    token = params.get(TOKEN_KEY) as string
  }

  setLocalStorage(TOKEN_KEY, token)
  return token
}
