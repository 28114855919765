import classNames from 'classnames'
import { forwardRef, InputHTMLAttributes, useId, useState } from 'react'

export type ToggleProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ className, onChange, defaultChecked, disabled, ...props }, ref) => {
    const id = useId()
    const [checked, setChecked] = useState(defaultChecked)

    return (
      <label
        htmlFor={id}
        style={{ aspectRatio: '2/1' }}
        className={classNames(
          'flex h-8 w-20 cursor-pointer items-center rounded-full px-1 transition-all',
          checked ? 'bg-teal2' : 'bg-neutral4',
          disabled && 'cursor-not-allowed opacity-40',
          className,
        )}
      >
        <input
          className="hidden"
          data-testid="toggle"
          disabled={disabled}
          id={id}
          ref={ref}
          type="checkbox"
          onChange={event => {
            setChecked(event.target.checked)
            onChange?.(event)
          }}
          {...props}
        />
        <div
          className={classNames(
            'aspect-square h-6 justify-self-end rounded-full bg-white transition-all',
            checked && 'translate-x-12',
          )}
        />
      </label>
    )
  },
)

Toggle.displayName = 'Toggle'
