import classNames from 'classnames'
import { FC, HTMLAttributes, PropsWithChildren } from 'react'

type ContainerProps = HTMLAttributes<HTMLDivElement>

export const Container: FC<PropsWithChildren<ContainerProps>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={classNames('container mx-auto px-4', className)} {...props}>
      {children}
    </div>
  )
}
