import classNames from 'classnames'
import { FC } from 'react'

import { Label, LabelProps } from './Label'

export type FloatingLabelProps = LabelProps & {
  isFloating?: boolean
}

export const FloatingLabel: FC<FloatingLabelProps> = ({
  isFloating = false,
  children,
  className,
  ...props
}) => {
  return (
    <Label
      className={classNames(
        'pointer-events-none absolute select-none transition-all pr-4',
        'text-neutral7 top-1/2 left-4 -translate-y-1/2',
        'peer-focus:-translate-y-4 peer-focus:text-xs',
        isFloating && '-translate-y-4 text-xs',
        className,
      )}
      {...props}
    >
      {children}
    </Label>
  )
}

FloatingLabel.displayName = 'FloatingLabel'
