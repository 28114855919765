import { FC } from 'react'

import { Product } from '../../services/products/types'
import { Button, Card, Flex, Image, List, Text } from '../../ui'
import { useTreatmentContext } from './TreatmentProvider'

type ProductCardProps = {
  product: Product
}

export const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const { addToTreatment, getDescription } = useTreatmentContext()

  return (
    <Card isShadow className="bg-silver1 max-w-sm h-auto">
      <List className="h-full">
        {product.imageURL && (
          <Image
            alt={product.name}
            aspectRatio="aspect-square"
            className="max-w-[200px] self-center"
            objectFit="object-contain"
            src={product.imageURL}
          />
        )}
        <Text isBold>{product.name}</Text>
        <hr />
        <List gap="gap-4">
          <Text
            size="small"
            dangerouslySetInnerHTML={{
              __html: product.descriptions?.short ?? '',
            }}
          />
          <List gap="gap-0">
            <Text isBold size="small">
              Instructions:
            </Text>
            <Text size="small">{product.instructions.description}</Text>
            {product.instructions.dosage && (
              <Text isBold size="small">
                Dosage: {product.instructions.dosage}
              </Text>
            )}
            {product.instructions.medicationFrequency && (
              <Text isBold size="small">
                Frequency: {product.instructions.medicationFrequency}
              </Text>
            )}
          </List>
        </List>
        <Flex className="grow" />
        <List>
          {product.plans.map((plan, index) => {
            return (
              <Button
                key={index}
                className="bg-neutral1 shadow-lg text-base w-full"
                onClick={() =>
                  addToTreatment({
                    plan: {
                      ...plan,
                      imageURL: product.imageURL,
                      name: product.name,
                    },
                    quantity: 1,
                    name: product.name,
                    needsPrescription: product.needsPrescription,
                    slug: product.slug,
                    instructions: product.instructions,
                    refId: product.refId,
                    category: product.category,
                  })
                }
              >
                <Flex className="w-full text-xs flex-col">
                  <div className="flex w-full justify-between align-middle">
                    <div className="basis-1/3 text-left">Name:</div>
                    <div className="basis-1/3 text-right">{product.name}</div>
                  </div>
                  <div className="flex w-full justify-between align-middle">
                    <div className="basis-1/3 text-left">Description:</div>
                    <div className="basis-1/3 text-right">
                      {getDescription(plan)}
                    </div>
                  </div>
                  <div className="flex w-full justify-between align-middle">
                    <div className="basis-1/3 text-left">Price:</div>
                    <div className="basis-1/3 text-right">
                      ${plan.price.value.toFixed(2)}
                    </div>
                  </div>
                </Flex>
              </Button>
            )
          })}
        </List>
      </List>
    </Card>
  )
}
