import { createAxiosInstance, getToken } from '..'
import { ConsultationDetails } from './types'

export const getConsultation = async () => {
  const accessToken = getToken({ fromQueryParams: false })

  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const { data } = await axiosInstance.get<ConsultationDetails>(
    `/consultations/current?country=sg`,
  )

  return data
}
