import classNames from 'classnames'
import { FC } from 'react'
export type ImageProps = {
  alt?: string
  aspectRatio?: 'aspect-square' | 'aspect-video' | 'aspect-auto'
  className?: string
  src: string
  width?: number | string
  height?: number | string
  objectFit?: 'object-contain' | 'object-cover' | 'object-fill'
  objectPosition?: 'object-center'
}

export const Image: FC<ImageProps> = ({
  alt,
  aspectRatio = 'aspect-auto',
  className,
  src,
  height,
  width,
  objectFit = 'object-contain',
  objectPosition = 'object-center',
}) => {
  return (
    <div
      className={classNames(
        'flex',
        aspectRatio,
        objectFit,
        objectPosition,
        className,
      )}
    >
      <img alt={alt} height={height} src={src} width={width} />
    </div>
  )
}
