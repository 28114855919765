import { toast } from 'react-toastify'

export const useToaster = () => {
  return {
    error: (message: string) => {
      toast(message, {
        type: 'error',
        autoClose: 8000,
      })
    },
    info: (message: string, autoClose = 8000) => {
      toast(message, {
        type: 'info',
        autoClose,
      })
    },
    success: (message: string, autoClose = 8000) => {
      toast(message, {
        type: 'success',
        autoClose,
      })
    },
    dismissAll: () => {
      toast.dismiss()
    },
  }
}
