import { createAxiosInstance, getToken } from '..'

export type EndConsultationContext = {
  orderRef: string
}

export const endConsultation = async ({
  orderRef,
}: EndConsultationContext): Promise<boolean> => {
  const accessToken = getToken({ fromQueryParams: false })
  const axiosInstance = await createAxiosInstance({ token: accessToken })
  const context = {
    orderRef,
  }

  const { data } = await axiosInstance.post<boolean>(
    `/orders/${orderRef}/appointments/end`,
    context,
  )

  return data
}
